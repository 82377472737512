/* Genel yapı */
.cont {
  max-width: 100vw;
  max-height: 100vh;
  text-align: center;
  margin: 0 auto;
  overflow-x: hidden;
}

/* Başlık bölümü */
.cont .header {
  text-align: center;
  padding: 10vh 0;
  position: relative;
  background-image: url("/public/glow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cont .header p {
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #7815B7;
  margin-bottom: 0;
  font-weight: 300;
}

.cont .header h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 8vw; /* Dinamik genişlik tabanlı font boyutu */
  font-weight: bold;
  color: #000;
  margin: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
}

/* Bizimle iletişim bölümü */
.cont .bizimle-iletisim h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: rgba(27, 28, 30, 1);
  margin-bottom: 0;
  font-size: 5vw; /* Dinamik font boyutu */
}

.cont .bizimle-iletisim .subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 2vw;
  font-weight: 300;
  color: rgba(27, 28, 30, 0.5);
  margin-bottom: 10vh;
}

/* İletişim bölümünün düzenlemesi */
.cont .contact-section {
  display: flex;
  justify-content: space-between;
  max-width: 85vw;
  margin: auto;
  gap: 2vw;
  padding-top: 5vh;
  margin-bottom: 50px;
}

.cont .contact-form, .cont .contact-map {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.cont form {
  display: flex;
  flex-direction: column;
}

.cont .form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2vh;
  align-items: baseline;
}

.cont input, .cont textarea {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #000;
  font-size: 1.2rem;
  outline: none;
  margin-bottom: 2vh;
}

.cont input::placeholder, .cont textarea::placeholder {
  color: #000;
  opacity: 1;
  font-size: 1.2rem;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  padding-left: 5px;
}

.cont textarea {
  height: 10vh;
  resize: none;
  margin-top: 5vh;
}

.cont label {
  font-size: 1rem;
  color: #000;
  padding: 10px;
}

.cont button {
  padding: 1.5rem 0;
  background: linear-gradient(to right, #7712B6 5.81%, #B14BDB 92.95%);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.5vw;
  font-weight: bold;
  margin-top: 2vh;
  width: 100%;
  transition: all 0.3s ease; 
}


.cont button:hover {
  box-shadow: 0 0 20px rgba(177, 75, 219, 0.7), 0 0 40px rgba(177, 75, 219, 0.5); 
  transform: scale(1.05); 
}


.cont button:active {
  transform: scale(0.98); 
}

.cont .full-width{
  margin-top: 50px;
  margin-bottom: 1vh;
  padding-bottom: 0px;
}

/* Tablet ve daha küçük ekranlar için */
@media (max-width: 1024px) {
  .cont .header h1 {
    font-size: 6vw;
  }

  .cont .header p {
    font-size: 1.2rem;
  }

  .cont .contact-section {
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;
  }

  .cont .contact-form, .cont .contact-map {
    width: 90%;
    margin-bottom: 2vh;
  }

  .cont .bizimle-iletisim h2 {
    font-size: 6vw;
  }

  .cont .bizimle-iletisim .subtitle {
    font-size: 4vw;
  }

  .cont button {
    font-size: 2rem;
  }
}

/* Mobil ekranlar için */
@media (max-width: 768px) {
  .cont .header {
    background-image: url("/public/mobil.svg");
  }

  .cont .header h1 {
    font-size: 4rem;
  }

  .cont .header p {
    font-size: 1rem;
  }

  .cont .contact-section {
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;
  }

  .cont .contact-form, .cont .contact-map {
    width: 100%;
  }

  .cont .bizimle-iletisim h2 {
    font-size: 4rem; 
  }

  .cont .bizimle-iletisim .subtitle {
    font-size: 1rem;
  }

  .cont button {
    font-size: 1rem;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes glowIn {
  0% {
    opacity: 0.5;
    filter: brightness(50%);
  }
  100% {
    opacity: 1;
    filter: brightness(100%);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-5%);
  }
}

@keyframes glowOut {
  0% {
    opacity: 1;
    filter: brightness(100%);
  }
  100% {
    opacity: 0.5;
    filter: brightness(50%);
  }
}


.cont .text-animate-in {
  animation: slideIn 1.6s ease-out forwards;
}

.cont .text-animate-out {
  animation: slideOut 1.6s ease-in forwards;
}

.cont .background-animate-in {
  animation: glowIn 1.6s ease-out forwards;
}

.cont .background-animate-out {
  animation: glowOut 1.6s ease-in forwards;
}
