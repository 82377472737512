/* Genel ayarlar */
.app {
  object-fit: contain;
  max-width: 100%; /* Genişliği %100 ile sınırla */
  overflow-x: hidden; /* Yatay taşmayı engeller */
}

/* Hero Section */
.event .header {
  text-align: center;
  padding: 10vh 0;
  position: relative;
  background-image: url("/public/glow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event .header p {
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #7815B7;
  margin-bottom: 0;
  font-weight: 300;
}

.event .header h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 8vw; /* Dinamik genişlik tabanlı font boyutu */
  font-weight: bold;
  color: #000;
  margin: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
}


@media (max-width: 768px) {
  .event .header{
    background-image: url("/public/mobil.svg");
  }
  .event .header h1 {
    font-size: 10vw; /* Küçük ekranlar için başlık boyutunu küçültüyoruz */
  }

  .event .header p {
    font-size: 1.2rem; /* Küçük ekranlar için alt metni küçültüyoruz */
  }
}

/* Main Component */
.event .main-component {
  max-width: 90%; /* Genişliği %90 yaparak ekrana sığdırıyoruz */
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5vh 2vw; /* Yüksekliğe ve genişliğe göre padding */
  gap: 2vw;
  position: relative;
}
.event .baslik {
 display: none;
}
.event .baslik1{
  display: none;
}

@media (max-width: 768px) {
  .event .main-component {
    flex-direction: column; /* Küçük ekranlarda bileşenler üst üste gelecek */
    padding: 3vh 2vw; /* Küçük ekranlar için padding ayarı */
  }
  .event .baslik{
    margin-bottom: 0px;
    margin-top: 0px;
    display: block;
    font-size:6vw ;
  }
  .event .baslik1{
    margin-bottom: 0px;
    margin-top: 0px;
    display: block;
    font-size:5vw ;
  }
}

/* Carousel wrapper */
.image-carousel {
  position: relative;
  width: 100%;
  max-width: 500px; /* Maksimum genişlik 500px olarak ayarlandı */
  height: auto;
  overflow: hidden; /* Görünen alan dışına taşan resimler gizlenecek */
}

.carousel-image-container {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Kayma geçiş süresi */
}

.carousel-image {
  width: 100%;
  height: auto; /* Ekrana sığdır */
  object-fit: cover;
  border-radius: 14px;
}

/* Kayma sınıfı */
.sliding {
  transition: transform 1s ease-in-out; /* Animasyon süresi (kayma hızı) */
}

@media (max-width: 768px) {
  .image-carousel {
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
  }
}

/* Sağ taraftaki metin alanı */
.event .text-component {
  flex: 1;
  width: 100%;
  background-color: white;
  padding: 2vh 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
}

.event .text-component h1 {
  font-size: 4vw; /* Ekran genişliğine göre ayarlandı */
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1vh;
}

.event .text-component p {
  margin-top: 1vh;
  font-size: 1.6rem;
  color: #666;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px) {
  .event .text-component h1 {
    font-size: 6vw; /* Başlığı küçült */
    display: none;
    
  }

  .event .text-component p {
    font-size: 1rem; /* Açıklama metni küçültülüyor */
  }
}

.event .text-link-reverse {
  position: relative;  
  left: 80%;
  right: 0;  
  top: 50%;  
  display: block;  
 
}
.event .text-link-reverse img{
  width: 100px;
  height: 100px;
}


.event .text-link-reverse:hover {

}

.event .text-link {
  position: relative;   
  right: 80%; 
  left: 3%;
  top: 50%;   
  display: block; 
}
.event .text-link img {
  width: 100px;
  height: 100px;
}

.event .text-link:hover {
 
}

.event .text-animate-in {
  animation: slideIn 1.6s ease-out forwards;
}

.event .text-animate-out {
  animation: slideOut 1.6s ease-in forwards;
}


@media (max-width: 768px) {
  .event .text-link img {
    width: 80px;
    height: 80px;
  }
  .event .text-link-reverse img{
    width: 80px;
    height: 80px;
  }
}

/* Carousel Buttons */
.event .prev-button, .event .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;

}

.event .prev-button {
  left: 10px;;
}

.event .next-button {
  right: 10px;
}

@media (max-width: 768px) {
  .event .prev-button, .event .next-button {
    font-size: 0.75rem;
    padding: 0.75rem;
  }
}


.event .reverse {
  flex-direction: row-reverse;
}

/* Mobil ve tabletler için düzenleme */
@media (max-width: 768px) {
  .event .main-component.reverse {
    flex-direction: column;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes glowIn {
  0% {
    opacity: 0.5;
    filter: brightness(50%);
  }
  100% {
    opacity: 1;
    filter: brightness(100%);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(5%);
  }
}

@keyframes glowOut {
  0% {
    opacity: 1;
    filter: brightness(100%);
  }
  100% {
    opacity: 0.5;
    filter: brightness(50%);
  }
}


.event .background-animate-in {
  animation: glowIn 1.6s ease-out forwards;
}

.event .background-animate-out {
  animation: glowOut 1.6s ease-in forwards;
}