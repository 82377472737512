form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;

}


.MuiTextField-root {
    width: 500px;
    margin: 8px;
}