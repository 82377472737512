.hero .header {
  text-align: center;
  padding: 10vh 0;
  position: relative;

  height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero .header p {
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: calc(0.4vw + 1.6vh);
  color: #7815B7;
  margin-bottom: 0;
  font-weight: 300;
}

.hero .header h1 {
  font-family: 'Poppins', sans-serif;
  font-size: calc(3vw + 7vh);
  line-height: 1em;
  /* Dinamik genişlik tabanlı font boyutu */
  font-weight: bold;
  color: #1B1C1E;
  margin: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
}



/* Animasyon Bölümü */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes glowIn {
  0% {
    opacity: 0.5;
    filter: brightness(75%);
  }

  100% {
    opacity: 1;
    filter: brightness(100%);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes glowOut {
  0% {
    opacity: 1;
    filter: brightness(100%);
  }

  100% {
    opacity: 0.5;
    filter: brightness(75%);
  }
}

.hero .text-animate-in {
  animation: slideIn 1.5s ease-out forwards;
}

.hero .text-animate-out {
  animation: slideOut 1.5s ease-in forwards;
}


.hero .background-animate-in {
  animation: glowIn 1.5s ease-out forwards;
}

.hero .background-animate-out {
  animation: glowOut 1.5s ease-in forwards;
}

.mobile-bigger {
  scale: 75%;

  top: calc(55vh - 15vw)
}

@media (max-width: 880px) {
  .mobile-bigger {
    scale: 150%;
    top: calc(70vh - 16vw)
  }

  .hero .header h1 {
    font-size: calc(4vw + 5vh);
    color: #1B1C1E;
  }

}

@media (max-width: 500px) {
  .mobile-bigger {
    scale: 180%;
    top: calc(65vh - 10vw)
  }


  .hero .header h1 {
    font-size: calc(3vw + 3vh);
    color: #1B1C1E;
  }
}
